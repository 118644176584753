import React from "react";
import mansion from "../assets/mansion.png";
import mp from "../assets/monopoly-gif1.gif";
import milfred from "../assets/milfred.png";
import { motion } from "framer-motion";

function Header() {
  return (
    <header className="relative w-full h-[70vh] flex flex-col justify-center items-center bg-[#CBE9E1]">
      <div className="relative w-full h-full">
        <img
          src={mansion}
          alt="mansion background"
          className="w-full h-full object-cover absolute top-0 left-0 z-0"
        />
        <img
          src={mp}
          alt="mp"
          className="w-44 object-contain absolute bottom-0 left-1/2 transform -translate-x-1/2 z-1"
        />
      </div>
      <div className="absolute w-full flex flex-col justify-center items-center">
        <motion.img
          initial={{ opacity: 0, y: "20px" }}
          whileInView={{ opacity: 1, y: "0px" }}
          transition={{ duration: 2, delay: 0.2 }}
          viewport={{ once: true }}
          src={milfred}
          alt="milfred"
          className="w-[75%] md:w-[40%] object-contain max-w-xl drop-shadow-lg z-2"
        />
      </div>
    </header>
  );
}

export default Header;
