import { useState } from "react";
import boeing from "../assets/boeing-plane.gif";
import jp from "../assets/jp.png";
import { motion } from "framer-motion";

function About() {
  const [showToast, setShowToast] = useState(false);

  const copyContract = () => {
    const contract = "your_contract_address_here";
    navigator.clipboard.writeText(contract).then(() => {
      setShowToast(true);
      setTimeout(() => {
        setShowToast(false);
      }, 2000);
    });
  };

  return (
    <div className="flex flex-col justify-center items-center my-10 max-w-2xl mx-auto">
      <div className="flex justify-center items-center gap-3 flex-wrap mb-20 text-center">
        <button
          onClick={copyContract}
          className="w-full relative flex justify-center font-semibold text-lg py-3 px-10 rounded-lg drop-shadow-lg border-blue-600  bg-blue-600 hover:bg-blue-400 text-white transition ease-in-out duration-200"
        >
          Copy Contract 💰
          {showToast && (
            <motion.div
              initial={{ opacity: 0, y: 50 }}
              animate={{ opacity: 1, y: 0 }}
              exit={{ opacity: 0, y: 50 }}
              className="absolute bottom-20  bg-green-600 text-white font-semibold py-2 px-4 rounded-lg drop-shadow-lg"
            >
              Contract copied
            </motion.div>
          )}
        </button>
        <a
          href="https://t.me/yourtelegram"
          target="_blank "
          rel="noopener noreferrer"
          className="bg-white flex-1 font-semibold text-lg py-2 px-10 rounded-lg drop-shadow-lg border-black hover:border-red-600 border-[1px] hover:bg-red-400 hover:text-white transition ease-in-out duration-200"
        >
          Telegram
        </a>
        <a
          href="https://twitter.com/yourtwitter"
          target="_blank"
          rel="noopener noreferrer"
          className="bg-white flex-1 font-semibold text-lg py-2 px-10 rounded-lg drop-shadow-lg border-black hover:border-red-600 border-[1px] hover:bg-red-400 hover:text-white transition ease-in-out duration-200"
        >
          Twitter
        </a>
      </div>
      <div className="flex flex-col">
        <h1 className="text-5xl">Who is Milfred Pennybags?</h1>
        <div>
          <p className="text-xl py-6">
            The famous Monopoly character; based off of J.P. Morgan! And he's
            come to Solana!
          </p>
          <motion.img
            initial={{ opacity: 0, y: "10px" }}
            whileInView={{ opacity: 1, y: "0px" }}
            transition={{ duration: 1, delay: 0.2 }}
            viewport={{ once: true }}
            src={jp}
            alt="jp"
          />
        </div>
        <div />
      </div>
    </div>
  );
}
export default About;
