import React from "react";
import logo from "../assets/monopoly logo.png";

function Footer() {
  return (
    <footer className="text-black py-8 flex flex-col items-center justify-center w-[80%] mx-auto">
      <div className="flex flex-col md:flex-row items-center justify-between">
        <div className="flex items-center mb-4 md:mb-0">
          <img src={logo} alt="Logo" className="h-12 mr-0 md:mr-10 w-40" />
        </div>
        <div className="flex flex-wrap gap-6 md:text-lg font-medium justify-center">
          <a
            href="https://t.me/yourtelegram"
            target="_blank"
            rel="noopener noreferrer"
          >
            Telegram
          </a>
          <a
            href="https://twitter.com/yourtwitter"
            target="_blank"
            rel="noopener noreferrer"
          >
            Twitter
          </a>
          <a
            href="https://dexscreener.com/yourlink"
            target="_blank"
            rel="noopener noreferrer"
          >
            Dexscreener
          </a>
          <a
            href="https://birdeye.so/yourlink"
            target="_blank"
            rel="noopener noreferrer"
          >
            Birdeye
          </a>
          <a
            href="https://solscan.io/yourlink"
            target="_blank"
            rel="noopener noreferrer"
          >
            Solscan
          </a>
        </div>
      </div>
    </footer>
  );
}

export default Footer;
