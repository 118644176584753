import { motion } from "framer-motion";
import token from "../assets/token.png";
import print from "../assets/print.gif";

function Services() {
  return (
    <div className="flex flex-col justify-center items-center max-w-2xl mx-auto">
      <div className="py-8 px-8 bg-red-600 rounded-lg mb-10 md:my-24">
        <h3 className="text-white font-semibold text-2xl text-center">
          Contract Renounced! Tax 0/0, Liquidity locked
        </h3>
      </div>

      <div className="flex flex-col mb-24">
        <h1 className="text-5xl">$MILFRED Token</h1>
        <div className="relative">
          <p className="text-xl py-6">
            Unlike the US dollar, $MILFRED is more scarce and has a supply of 1
            Billion tokens. The only money printing here is how high we're going
            to send it.
          </p>
          <div className="flex justify-center flex-wrap gap-2">
            <motion.img
              initial={{ opacity: 0, y: "10px" }}
              whileInView={{ opacity: 1, y: "0px" }}
              transition={{ duration: 1, delay: 0.2 }}
              viewport={{ once: true }}
              src={token}
              alt="jp"
            />
            <motion.img
              initial={{ opacity: 0, y: "10px" }}
              whileInView={{ opacity: 1, y: "0px" }}
              transition={{ duration: 1, delay: 0.2 }}
              viewport={{ once: true }}
              src={print}
              alt="jp"
              className="rounded-lg"
            />
          </div>
        </div>
        <div />
      </div>
    </div>
  );
}
export default Services;
